import React from "react"
import styled from "styled-components"

const LabelTextWrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(2)}px;

    .label {
      width: 100%;
      flex-shrink: 0;
      font-family: "Noto Serif TC", serif;
      font-weight: 900;
      line-height: 1.5rem;
      border-bottom: #555555 1px solid;
    }
  `}
`

const ShortLabelTextWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: baseline;
    margin-bottom: ${theme.spacing(1)}px;
    
    .label {
      font-family: "Noto Serif TC", serif;
      font-size: 0.875rem;
      border-right: ${theme.palette.primary.contrastText} 1px solid;
      padding-right: ${theme.spacing(1)}px;
      margin-right: ${theme.spacing(1)}px;
      max-width: 40vw;
      ${theme.breakpoints.up("viewport7")} {
        flex-shrink: 0;
      }
    }
  `}
`

export default function LabelText({ label, text, ...props }) {
  return (
    <LabelTextWrapper {...props}>
      {label && <div className="label">{label}</div>}
      <div className="text">{text}</div>
    </LabelTextWrapper>
  )
}

export function ShortLabelText({ label, text, ...props }) {
  return (
    <ShortLabelTextWrapper {...props}>
      {label && <div className="label">{label}</div>}
      <div className="text">{text}</div>
    </ShortLabelTextWrapper>
  )
}
