import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import SEO from "@/components/SEO"
import { withLanguage } from "@/utils/i18n"
import Layout from "@/components/Layout"
import { graphql } from "gatsby"
import LabelText, { ShortLabelText } from "@/components/LabelText"
import CentreContainer from "@/components/Container"
import FullWidthImage from "@/components/Swiper/FullWidthImage"
import RelatedCard from "@/components/Swiper/RelatedCard"
import { useBasicStyle } from "@/theme"
import { StickyCard } from "@/components/StickyCard"
import Legend from "@/components/Legend"
import marked from "marked"
import Back from "@/components/Back"
import DefendantDots from "@/DefendantDots"
import Loader from "@/components/Loader"
import { trackCustomEvent } from "@/utils/ga"

const ChargeTemplate = ({
  pageContext: { localePath, allCharges },
  data: {
    cms: { charge },
  },
}) => {
  const { i18n, t } = useTranslation()
  const classes = useBasicStyle()
  const [thisCharge] = charge
  const chargeIndex = allCharges.findIndex(c => c.id === thisCharge.id)
  const otherCharges = [
    ...allCharges.slice(chargeIndex + 1),
    ...allCharges.slice(0, chargeIndex),
  ]

  const [selectedLegendId, setSelectedLegendId] = useState(-1)

  const [defendants, setDefendants] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      let data = await fetch(
        `${window.location.origin}/data/charges/${thisCharge.id}.json`
      ).then(responses => responses.json())

      setDefendants(data.defendants)
      setIsLoading(false)
    }

    fetchData()
  }, [])

  const selectedDefendants = defendants
    .filter(
      d => selectedLegendId == -1 || [selectedLegendId].includes(d.progress.id)
    )
    .sort((a, b) => a.progress.id - b.progress.id)

  return (
    <div>
      <Layout localePath={localePath}>
        <SEO
          title={withLanguage(i18n, thisCharge, "name")}
          description={withLanguage(i18n, thisCharge, "content")}
        />
        <div>
          <FullWidthImage>
            <div className="header">
              <div className="header__Name">
                {withLanguage(i18n, thisCharge, "name")}
              </div>
              <div className="header__DefendantCount">
                {t("common.defendant_count", { count: defendants.length })}
              </div>
            </div>
          </FullWidthImage>
          <CentreContainer>
            <Back />
          </CentreContainer>
          <CentreContainer>
            <LabelText
              label={withLanguage(i18n, thisCharge, "ordinance")}
              text={
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(withLanguage(i18n, thisCharge, "content")),
                  }}
                />
              }
            />
            <ShortLabelText
              label={t("charge.max_penalty")}
              text={withLanguage(i18n, thisCharge, "max_penalty")}
            />
          </CentreContainer>
          <div className={classes.legendWrapper}>
            <Legend
              filterable={true}
              type="charge"
              activeId={selectedLegendId}
              handleClick={(clickedIndex, items) => {
                setSelectedLegendId(
                  items[clickedIndex].id == selectedLegendId
                    ? -1
                    : items[clickedIndex].id
                )
                trackCustomEvent("Click", {
                  event_category: "Legend",
                  event_label: `${withLanguage(i18n, thisCharge, "name")} - ${
                    items[clickedIndex].title
                  }`,
                })
              }}
            />
          </div>
          <CentreContainer>
            {isLoading ? (
              <Loader />
            ) : (
              <DefendantDots
                selectedDefendants={selectedDefendants}
                context="progress"
              />
            )}
          </CentreContainer>
          <div className={classes.fullWidthSecondary}>
            <CentreContainer padding={0}>
              <RelatedCard
                items={otherCharges.map(charge => ({
                  id: charge.id,
                  name: withLanguage(i18n, charge, "name"),
                  to: {
                    page: "template-charge",
                    lang: i18n.language,
                    obj: charge,
                  },
                  countText: t("common.defendant_count", {
                    count: charge.defendantCount,
                  }),
                }))}
              />
            </CentreContainer>
          </div>
          <StickyCard />
        </div>
      </Layout>
    </div>
  )
}

export default ChargeTemplate

export const query = graphql`
  query ChargeQuery($chargeId: Int!) {
    cms {
      charge: charges(where: { id: { _eq: $chargeId } }) {
        ...ChargeBasicInfo
        ordinance_zh
        ordinance_en
        max_penalty_zh
        max_penalty_en
      }
    }
  }
`
